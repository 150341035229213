<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="vl-parent">
    <v-row>
      <v-col
        cols="4"
        offset-md="4"
      >
        <v-progress-circular
          v-if="isLoading"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          class="text-center"
        ></v-progress-circular>
        <!--<template v-else>
          <v-alert
            v-if="paymentStatus"
            cols="6"
            dense
            text
            type="success"
          >
            Su pago ha sido satisfactorio<br />
            <b>{{ payment.amount.value }} <i>{{ payment.amount.currency }}</i></b>
          </v-alert>
          <v-alert
            v-if="!paymentStatus"
            cols="6"
            dense
            text
            type="error"
          >
            Su pago no ha sido posible comprobarlo<br />
          </v-alert>
        </template>-->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      payment: {
        amount: {
          currency: null,
          value: null,
        },
        paymentMethod: null,
      },
      paymentStatus: false,
      carItems: JSON.parse(localStorage.getItem('car-shop')),
      clientCarShop: JSON.parse(sessionStorage.getItem('clientCarShop')),
    }
  },
  computed: {
    ...mapState({
      // carItems: state => state.app.carItems,
      loadingBtn: state => state.app.loadingBtn,
    }),
  },
  created() {
    this.getStatusTransaction()
  },
  methods: {
    ...mapMutations([
      'updateItemToCar',
      'emptyClientCarShop',
      'updateLoadingBtn',
      'setCotizadorCarsResult',
      'setCotizadorCars',
      'setCotizadorCarsResultSelect',
      'setCotizadorPaxCarSelect',
      'setSearchingCar',
      'emptyClientCarShop',
      'setPosCotizador',
    ]),
    getStatusTransaction() {
      const transactionID = sessionStorage.getItem('transactionID')
      const token = sessionStorage.getItem('tokenSBS')
      const clientID = sessionStorage.getItem('clientSBS')
      const url = sessionStorage.getItem('urlSBS')
      this.axios
        .get(`${url}/${transactionID}/status`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'X-IBM-Client-Id': clientID,
          },
        })
        .then(res => {
          // Declined, Pending
          if (res.data.paymentStatus === 'Success') {
            this.payment = res.data

            this.paymentStatus = true
            this.$toast.success('Su pago ha sido satisfactorio')

            this.pay()
          } else {
            this.$router.push({ name: 'car-shop-items' })
            this.$toast.error('Su pago ha sido rechazado')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    pay() {
      const action = sessionStorage.getItem('actionPay')
      const arrItemsCheck = []
      this.carItems.forEach(element => {
        if (element.check) {
          arrItemsCheck.push(element)
        }
      })
      if (arrItemsCheck.length > 0) {
        if (action === 'pays') {
          const jsonCar = {
            items: arrItemsCheck,
            data_client: this.clientCarShop,
            action: 'pay',
            slug: 'payCard',
            evidencia: null,
            creditPay: null,
            saldoFavorPay: null,
            password: null,
            cardName: null,
            cardNo: null,
            cardDateExpiret: null,
            cardCVV: null,
            payWithPayPal: false,
            orderIdPayPal: null,
            payWithSibs: true,
            transactionID: sessionStorage.getItem('transactionID'),
          }

          this.axios
            .post('reservations/generate', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR COTIZADOR
                this.clearCotizador()
              } else if (res.data.data.status === 302) {
                this.$toast.error(this.$t('msg.passwordNotMath'))
              } else if (res.data.data.status === 401) {
                this.$toast.error(res.data.data.message)
              }
            })
        } else if (action === 'convert-to-reserve-pays') {
          const jsonCar = {
            id: localStorage.getItem('reservations-id'),
            items: arrItemsCheck,
            data_client: this.clientCarShop,
            action: 'pay',
            slug: 'payCard',
            evidencia: null,
            creditPay: null,
            password: null,
            saldoFavorPay: null,
            payWithPayPal: false,
            orderIdPayPal: null,
            payWithSibs: true,
            transactionID: sessionStorage.getItem('transactionID'),
          }

          this.axios
            .post('reservations/convert-to-reserva', jsonCar, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              if (res.data.success) {
                // LIMPIAR
                this.clearCotization()
              } else if (res.data.data.status === 302) {
                this.$toast.error(this.$t('msg.passwordNotMath'))
              }
            })
        }
      }
    },
    clearCotizador() {
      // REGRESARLO AL COTIZADOR
      setTimeout(() => {
        this.$router.push({ name: 'cotizador' })
      }, 105)
      sessionStorage.setItem('clear-cotization', 'hotels')
      this.setCotizadorCarsResult([])
      setTimeout(() => {
        this.setSearchingCar(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        localStorage.removeItem('car-shop')
      }, 100)

      // LIMPIAR COSAS DE SIBS
      this.clearSibs()
    },
    clearCotization() {
      // REGRESARLO A LAS RESERVAS
      setTimeout(() => {
        this.$router.push({ name: 'reservations' })
      }, 105)
      sessionStorage.setItem('clear-cotization', 'hotels')
      this.setCotizadorCarsResult([])
      setTimeout(() => {
        this.setSearchingCar(false)

        this.emptyClientCarShop()
        this.updateItemToCar([])
        this.setPosCotizador(0)
        localStorage.removeItem('car-shop')
        localStorage.removeItem('convert-to-reservation')
      }, 100)

      // LIMPIAR COSAS DE SIBS
      this.clearSibs()
    },

    clearSibs() {
      sessionStorage.removeItem('transactionID')
      sessionStorage.removeItem('tokenSBS')
      sessionStorage.removeItem('clientSBS')
      sessionStorage.removeItem('urlSBS')
      sessionStorage.removeItem('clientCarShop')
    },
  },
}
</script>
